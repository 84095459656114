import { default as _91_46_46_46slug_93cMy8BlP8k8Meta } from "/var/www/vhosts/twentserevue.nl/hengeloserevue.nl/pages/[...slug].vue?macro=true";
import { default as nieuwsdx4N4f9WZEMeta } from "/var/www/vhosts/twentserevue.nl/hengeloserevue.nl/pages/nieuws.vue?macro=true";
import { default as pers_45kit8pj344mGDMMeta } from "/var/www/vhosts/twentserevue.nl/hengeloserevue.nl/pages/pers-kit.vue?macro=true";
import { default as styleguideL0nrLOdJl4Meta } from "/var/www/vhosts/twentserevue.nl/hengeloserevue.nl/pages/styleguide.vue?macro=true";
import { default as component_45stubQvz0gtztJVMeta } from "/var/www/vhosts/twentserevue.nl/hengeloserevue.nl/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubQvz0gtztJV } from "/var/www/vhosts/twentserevue.nl/hengeloserevue.nl/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/var/www/vhosts/twentserevue.nl/hengeloserevue.nl/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "nieuws",
    path: "/nieuws",
    component: () => import("/var/www/vhosts/twentserevue.nl/hengeloserevue.nl/pages/nieuws.vue").then(m => m.default || m)
  },
  {
    name: "pers-kit",
    path: "/pers-kit",
    component: () => import("/var/www/vhosts/twentserevue.nl/hengeloserevue.nl/pages/pers-kit.vue").then(m => m.default || m)
  },
  {
    name: "styleguide",
    path: "/styleguide",
    component: () => import("/var/www/vhosts/twentserevue.nl/hengeloserevue.nl/pages/styleguide.vue").then(m => m.default || m)
  },
  {
    name: component_45stubQvz0gtztJVMeta?.name,
    path: "/verhuurfolder",
    component: component_45stubQvz0gtztJV
  },
  {
    name: component_45stubQvz0gtztJVMeta?.name,
    path: "/filmpjes",
    component: component_45stubQvz0gtztJV
  },
  {
    name: component_45stubQvz0gtztJVMeta?.name,
    path: "/sponsoren",
    component: component_45stubQvz0gtztJV
  },
  {
    name: component_45stubQvz0gtztJVMeta?.name,
    path: "/videos-2023",
    component: component_45stubQvz0gtztJV
  }
]